.statusChip {
    width: 60px;
    height: 25px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 16px;
}

.statusChip:first-letter {
    text-transform: uppercase;
}

.statusNew {
    background: #F44545;
}

.statusPaying {
    background: royalblue;
}

.statusPaid {
    background: #0D9157;
}

.statusUnpaid {
    background: orange;
}