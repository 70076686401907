.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
}

@media (max-width: 480px) {
    .container {
        margin-top: 60px;
    }
}