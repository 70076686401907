.container {
    position: relative;
    border: 2px solid #667080;
    background: #1C1C1C;
    border-radius: 16px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
    margin: 30px 0 0 0;
}

.name {
    margin-bottom: 24px !important;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }


    .name {
        margin: 0;
    }
}
