.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94px;
    border-bottom: 2px solid rgba(102, 112, 128, 0.4);
    padding: 0 15px;
}
.logo {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.header img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .logo {
        margin-right: 20px;

    }
}

@media (max-width: 480px) {
    .header {
        height: 54px;
    }
}
