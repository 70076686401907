.table_container {
    position: relative;
    height: auto;
    max-height: 624px;
    max-width: 1216px;
    border: 2px solid #667080;
    background: #1C1C1C !important;
    border-radius: 16px !important;
    overflow: auto;
    margin: 32px 15px;
    width: unset !important;
}

.loading_container {
    position: relative;
    height: 624px;
    /*max-height: 624px;*/
    max-width: 1216px;
    border: 2px solid #667080;
    background: #1C1C1C !important;
    border-radius: 16px !important;
    overflow: scroll;
    margin: 32px 15px;
    width: unset !important;
}

.table_container td {
    background: #1C1C1C;
    color: #ffffff !important;
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: rgba(102, 112, 128, 0.4);
    font-size: 12px;
    font-weight: 500;
}

.table_container tbody tr:nth-last-child(1) td {
    border-width: 0 1px 0 0
}

.table_container tbody tr td:nth-last-child(1) {
    /*border-width: 1px 0 0 0*/
    border-right: 0;
}

.table_container tbody tr:nth-child(1) td:nth-last-child(1) {
    /*border-width: 0*/
}

.table_container th {
    background: #1C1C1C;
    color: #ffffff !important;
    border-color: rgba(102, 112, 128, 0.4);
    font-size: 12px;
    font-weight: 500;
}
