.container {
    width: 100%;
    max-width: 1216px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0 15px;
}

.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1C1C1C;
    max-width: 476px;
    width: 100%;
    padding: 36px 56px;
    border-radius: 16px;
    border: 2px solid #667080;
    gap: 15px;
}

.orderBlock {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    width: 100%;
}

.mainContainer {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

@media (max-width: 768px) {
    .container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .block {
        padding: 15px 32px;
        height: 110px;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        margin-top: 20px;
    }

    .container {
        gap: 20px;
    }
}