.container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 15px;
    gap: 20px;
}

@media (max-width: 480px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .container button {
        width: 100% !important;
    }
}